<script>
export default {
  props: {
    centroCusto: { type: Object },
  },
  data() {
    return {
        
    }
  },
}
</script>

<template>
<div class="centroCusto">
  <p>ID: {{ centroCusto.id }}</p>
  <p>Conta pai: {{ centroCusto.woner_id }}</p>
  <p>Conta: {{ centroCusto.conta }}</p>
  <p>Nome do Centro de Custo: {{ centroCusto.identificacao }}</p>
  <p>Ativo: <b-badge data-name="status" class="field-status" v-bind:variant="(centroCusto.status) ? 'success' : 'danger'">{{ (centroCusto.status) ? 'Sim' : 'Não' }}</b-badge></p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ centroCusto.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ centroCusto.updated_at }}</b-badge></p>
</div>
</template>